import { navigate } from 'gatsby';
import { useState } from 'react';
import { useAlert } from 'src/alert';
import { useQueryParam } from 'src/hooks';

function validate(password: string, confirmPassword: string): string | null {
  if (password.length < 8) {
    return 'Password must be at least 8 characters';
  }
  if (password !== confirmPassword) {
    return 'Passwords do not match';
  }
  return null;
}

export enum States {
  NORMAL,
  SUBMITTING,
  SUBMITTED,
}

export function useResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [state, setState] = useState(States.NORMAL);
  const [showInputError, setShowInputError] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);
  const alert = useAlert();
  const token = useQueryParam('token');

  const handlePasswordChange = (newPassword: string) => {
    setServerError(null);
    setPassword(newPassword);
  };

  const handleConfirmPasswordChange = (newPassword: string) => {
    setServerError(null);
    setConfirmPassword(newPassword);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!token) {
      setServerError('Password reset token is required');
      return;
    }

    const hasError = validate(password, confirmPassword);
    if (hasError) {
      setShowInputError(true);
      return;
    }

    try {
      setState(States.SUBMITTING);
      await window.apis['auth-api'].auth.resetPassword({
        token,
        password: {
          password,
        },
      });
      setState(States.SUBMITTED);
      setTimeout(() => navigate('/login'), 3000);
    } catch (err) {
      setState(States.NORMAL);
      // @ts-expect-error TODO
      if (err.statusCode === 404) {
        setServerError('Token is invalid or has expired. Please request another token by starting over.');
      } else {
        alert.error('Something went wrong! Please try again in a few minutes.');
      }
    }
  };

  const inputError = showInputError && validate(password, confirmPassword);

  return {
    password,
    confirmPassword,
    handlePasswordChange,
    handleConfirmPasswordChange,
    state,
    inputError,
    serverError,
    handleSubmit,
  };
}
