import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import ResetPasswordForm from 'src/sections/Auth/ResetPassword/Form';
import ApiWrapper from 'src/components/ApiWrapper';

const ResetPasswordPage: React.FC = () => (
  <ApiWrapper apiServices={['auth-api', 'home-api']}>
    <Layout>
      <Seo title="Reset Password - Bizwise" />
      <ResetPasswordForm />
    </Layout>
  </ApiWrapper>
);

export default ResetPasswordPage;
