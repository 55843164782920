import React from 'react';
import { EyeIcon, EyeOffIcon, ChevronDownIcon } from '@heroicons/react/solid';
import cx from 'clsx';
import Typography from 'src/components/core/Typography';
import { pxToRem } from 'src/theme';
import { IntentionalAny } from 'src/types';
import Clickable from './Clickable';

interface InputProps {
  label?: string,
  labelIcon?: React.ElementType,
  topRightNode?: React.ReactNode,
  error?: string | boolean | null,
  sensitive?: boolean,
  optional?: boolean,
  inputClassName?: string,
  className?: string,
  style?: React.CSSProperties,
  name: string,
  value?: string,
  onChange: (newValue: string) => void,
  inputProps?: React.HTMLProps<HTMLInputElement>,
  labelProps?: React.HTMLProps<HTMLLabelElement>,
  type?: 'input' | 'textarea' | 'select',
  children?: React.ReactNode,
  customComponent?: {
    component: React.ElementType,
    props?: {
      [key: string]: IntentionalAny,
    },
    onChange: (e: IntentionalAny) => string,
  },
}
export type Props = InputProps;

type RefElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
const Input = React.forwardRef<RefElement, InputProps>(({
  label,
  labelIcon: LabelIcon,
  topRightNode,
  error,
  sensitive,
  optional,
  name,
  inputClassName,
  className,
  style,
  value,
  onChange,
  inputProps,
  labelProps,
  type = 'input',
  children,
  customComponent,
}, ref) => {
  const [visible, setVisible] = React.useState(!sensitive);
  const tailWindClasses = [
    'px-[1.25rem]',
    'py-[0.9rem]',
    sensitive && 'pr-[2.75rem]',
    'focus:px-[calc(1.25rem-1px)]',
    'focus:py-[calc(0.9rem-1px)]',
    sensitive && 'focus:pr-[calc(2.75rem-1px)]',
    'outline-none',
    'focus:outline-none',
    'border',
    'border-gray-outline',
    'focus:border-teal',
    'focus:border-2',
    error && 'border-error',
    error && 'focus:border-error',
    error && 'bg-error-background',
    'block',
    'w-full',
    'shadow-sm',
    'rounded-md',
    'text-blue',
    !error && 'bg-white',
    label && 'mt-1',
  ];
  const VisibilityIcon = visible ? EyeOffIcon : EyeIcon;
  const errorComponent = error && <Typography variant="p3" color="error" className="block mt-2 color-error">{error}</Typography>;
  let input;
  if (customComponent) {
    const {
      component: InputComponent,
      props: inputComponentProps,
      onChange: inputComponentOnChange,
    } = customComponent;
    input = (
      <InputComponent
        ref={ref}
        id={name}
        name={name}
        className={cx(tailWindClasses, inputClassName)}
        value={value}
        onChange={(e: unknown) => onChange(inputComponentOnChange(e))}
        type={!visible ? 'password' : 'text'}
        {...inputProps}
        {...inputComponentProps}
      />
    );
  } else if (type === 'textarea') {
    input = (
      <textarea
        // @ts-expect-error TODO
        ref={ref}
        id={name}
        name={name}
        className={cx(tailWindClasses, inputClassName)}
        value={value}
        /* @ts-expect-error Type error when using same onChange for input */
        onChange={(e) => onChange(e.target.value)}
        {...inputProps}
      />
    );
  } else if (type === 'select') {
    input = (
      <div className="relative">
        <select
          // @ts-expect-error TODO
          ref={ref}
          id={name}
          name={name}
          className={cx(tailWindClasses, inputClassName)}
          style={{ MozAppearance: 'none', WebkitAppearance: 'none' }}
          value={value}
          /* @ts-expect-error Type error when using same onChange for input */
          onChange={(e) => onChange(e.target.value)}
          {...inputProps}
        >
          {children}
        </select>
        <span className="absolute inset-y-0 right-0 flex items-center pr-[1rem]" style={{ pointerEvents: 'none' }}>
          <ChevronDownIcon className="w-[1.25rem] h-[1.25rem] text-teal" />
        </span>
      </div>
    );
  } else {
    input = (
      <div className="relative">
        <input
          // @ts-expect-error TODO
          ref={ref}
          id={name}
          name={name}
          className={cx(tailWindClasses, inputClassName)}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          type={!visible ? 'password' : 'text'}
          {...inputProps}
        />
        {sensitive && (
          <span className="absolute inset-y-0 right-0 flex items-center pr-[1rem]">
            <Clickable onClick={() => setVisible((old) => !old)}>
              <VisibilityIcon className="w-[1.25rem] h-[1.25rem] text-blue hover:text-blue-hover" />
            </Clickable>
          </span>
        )}
      </div>
    );
  }
  if (label) {
    return (
      <div className={className} style={style}>
        <label className="font-semibold text-blue" style={{ fontSize: pxToRem(16) }} htmlFor={name} {...labelProps}>
          <span className="flex items-center">
            {LabelIcon && <LabelIcon className="w-[1rem] h-[1rem] mr-[5px]" />}
            {label}
            {optional && <span className="font-normal self-end ml-[3px]" style={{ fontSize: pxToRem(14) }}>(Optional)</span>}
            {topRightNode && <div className="ml-auto">{topRightNode}</div>}
          </span>
        </label>
        {input}
        {errorComponent}
      </div>
    );
  }
  return (
    <div className={className} style={style}>
      {input}
      {errorComponent}
    </div>
  );
});

export default Input;
