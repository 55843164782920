import React, { useState, useEffect } from 'react';
import cx from 'clsx';
import useResizeObserver from 'use-resize-observer';
import { createUseStyles } from 'react-jss';
import {
  Theme, pxToRem, spacing, breakpoint,
} from 'src/theme';
import { assetLink } from 'src/utils';
import { useBreakpointToggleStyles } from 'src/hooks';
import { navbarHeight } from 'src/components/Navbar/utils';
import { AuthImages } from '../shared/StaticImages';

type Image = {
  id: number;
  img: React.ReactElement;
  height?: number;
};

const images: {
  left: Image[],
  right: Image[],
} = {
  left: [{
    id: 0,
    img: AuthImages[6],
    height: 248,
  }, {
    id: 1,
    img: AuthImages[0],
    height: 157,
  }, {
    id: 2,
    img: AuthImages[1],
    // height: 219,
  }],
  right: [{
    id: 3,
    img: AuthImages[5],
    height: 164,
  }, {
    id: 4,
    img: AuthImages[4],
    height: 134,
  }, {
    id: 5,
    img: AuthImages[3],
    height: 187,
  }, {
    id: 6,
    img: AuthImages[2],
    // height: 131,
  }],
};

const paperHeight = 640;
const childrenWidth = pxToRem(560);
const imagesWidth = pxToRem(440);
const padding = 8;
const borderRadius = 10;

interface StyleProps {
  initialBroken: boolean;
  showPhotos: boolean;
  contentHeight: number,
}

const useStyles = createUseStyles<string, StyleProps, Theme>({
  container: ({ contentHeight }) => ({
    position: 'relative',
    background: 'linear-gradient(99.96deg, #13266D 30.39%, #5FBCFF 153.01%), #F7F7F7',
    width: '100%',
    height: '100vh',
    minHeight: `calc(${contentHeight}px + ${navbarHeight} + ${spacing(16)})`,
    paddingTop: navbarHeight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  children: {
    width: '95vw',
    maxWidth: childrenWidth,
  },
  paper: {
    background: '#FFFFFF',
    borderRadius,
    position: 'relative',
    boxShadow: '0px 0px 40px rgb(0 0 0 / 15%)',
    zIndex: 1,
  },
  imagesContainer: {
    width: imagesWidth,
    display: 'flex',
    position: 'relative',
    overflow: 'hidden',
  },
  imagesColumn: ({ initialBroken }) => ({
    position: 'relative',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transitionDuration: '400ms',
    '&:first-child': {
      marginRight: initialBroken ? 80 + padding : padding,
      marginLeft: initialBroken ? -80 : 0,
    },
    '&:last-child': {
      marginTop: initialBroken ? 350 : 0,
    },
    '&:last-child > :first-child *': {
      borderTopRightRadius: borderRadius,
    },
    '&:last-child > :last-child *': {
      borderBottomRightRadius: borderRadius,
    },
    '&:last-child > *:hover': {
      left: `calc(-1 * (${imagesWidth} + ${padding}px) / 2)`,
    },
  }),
  imageContainer: {
    position: 'absolute',
    left: 0,
    width: `calc((${imagesWidth} - ${padding}px) / 2)`,
    transition: 'all 1s, z-index 0s',
    zIndex: 0,
    '&:hover': {
      top: '0 !important',
      width: imagesWidth,
      height: '100% !important',
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
      '& *': {
        borderTopRightRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      },
    },
  },
  staticImage: {
    height: '100%',
    width: '100%',
  },
  image: {
    transition: 'all 1s, z-index 0s',
    objectPosition: 'center',
  },
  row: ({ showPhotos }) => ({
    display: 'flex',
    minHeight: showPhotos ? pxToRem(paperHeight) : 'unset',
  }),
  shape: {
    pointerEvents: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    mixBlendMode: 'overlay',
    backgroundImage: `url('${assetLink('landing-ui/background-shapes/7.svg')}')`,
    backgroundSize: 'cover',
    backgroundPositionX: 'start',
    backgroundPositionY: 'top',
    [breakpoint.down('lg')]: {
      display: 'none',
    },
  },
});

interface Props {
  showPhotos?: boolean,
  children: React.ReactNode | React.ReactNode[],
}

export default function AuthBackground({ showPhotos = false, children }: Props) {
  const breakpointClasses = useBreakpointToggleStyles();
  const {
    height: imageColumnHeight = paperHeight,
    ref: imageColumnRef,
  } = useResizeObserver<HTMLDivElement>();
  const {
    height: contentHeight = 640,
    ref: contentRef,
  } = useResizeObserver<HTMLDivElement>();
  const [initialBroken, setInitialBroken] = useState(true);
  const [hoveredImageId, setHoveredImageId] = useState<null | number>(null);
  const classes = useStyles({ initialBroken, showPhotos, contentHeight });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitialBroken(false);
    }, 200);
    return () => clearTimeout(timeout);
  }, []);

  const renderColumnImage = (image: Image, idx: number, columnImages: Image[]) => {
    const top = columnImages
      .slice(0, idx)
      .reduce((acc, img) => acc + (img.height || 0) + padding, 0);
    return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <div
        key={image.id}
        className={classes.imageContainer}
        style={{
          top,
          height: image.height ? image.height : `calc(${pxToRem(imageColumnHeight)} - ${top}px)`,
          zIndex: hoveredImageId === image.id ? 1 : 0,
        }}
        onMouseOver={() => setHoveredImageId(image.id)}
      >
        {React.cloneElement(image.img, {
          key: image.id,
          className: classes.staticImage,
          imgClassName: classes.image,
        })}
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.paper}>
        <div className={classes.row} ref={contentRef}>
          <div className={classes.children}>{children}</div>
          {showPhotos && (
            <div className={cx(breakpointClasses.aboveLg, classes.imagesContainer)}>
              <div className={classes.imagesColumn} ref={imageColumnRef}>
                {images.left.map(renderColumnImage)}
              </div>
              <div className={classes.imagesColumn}>
                {images.right.map(renderColumnImage)}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={classes.shape} />
    </div>
  );
}
