import React from 'react';
import { LockClosedIcon, CheckCircleIcon } from '@heroicons/react/solid';
import Input from 'src/components/core/Input';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import { useQueryParam } from 'src/hooks';
import SectionContainer from '../../shared/SectionContainer';
import AuthBackground from '../Background';
import { useResetPassword, States } from './utils';

const ResetPasswordForm: React.FC = () => {
  const {
    password,
    confirmPassword,
    handlePasswordChange,
    handleConfirmPasswordChange,
    state,
    inputError,
    serverError,
    handleSubmit,
  } = useResetPassword();
  const flow = useQueryParam('flow');

  return (
    <SectionContainer background="light-blue" style={{ padding: 0 }}>
      <AuthBackground>
        <div className="pt-8 pb-14 px-8 sm:px-16 sm:pt-14">
          <Typography
            variant="h3"
            color="blue"
            className="mb-4"
            style={{ fontWeight: 700 }}
          >
            {flow === 'activate' ? 'Create Password' : 'Reset Password'}
          </Typography>
          {flow === 'activate' && (
            <Typography
              variant="p3"
              color="textSecondary"
              className="mb-4"
            >
              Create your password to access your new Bizwise account.
            </Typography>
          )}
          <form onSubmit={handleSubmit}>
            <Input
              className="mb-4"
              label="Password"
              name="password"
              error={inputError || serverError}
              labelIcon={LockClosedIcon}
              value={password}
              onChange={handlePasswordChange}
              sensitive
              inputProps={{
                autoComplete: 'new-password',
                placeholder: '************',
              }}
            />
            <Input
              label="Confirm Password"
              name="confirm-password"
              error={inputError || serverError}
              labelIcon={LockClosedIcon}
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              sensitive
              inputProps={{
                autoComplete: 'new-password',
                placeholder: '************',
              }}
            />
            <Button
              className="mt-5 mb-5 w-full"
              type="submit"
              variant="filled"
              color="teal"
              disabled={state !== States.NORMAL}
            >
              Change Password
            </Button>
          </form>
          {state === States.SUBMITTED && (
            <div className="rounded p-3 bg-teal-background">
              <Typography
                className="flex items-center justify-center color-teal"
                variant="p3"
                color="teal"
              >
                <CheckCircleIcon className="color-inherit mr-0.5 w-[24px] h-[24px]" />
                Password Successfully Changed
              </Typography>
            </div>
          )}
        </div>
      </AuthBackground>
    </SectionContainer>
  );
};

export default ResetPasswordForm;
